import PressureValue from "../utils/PressureValue";
import TemperatureValue from "../utils/TemperatureValue";
import moduleStore from "../settings/ModuleStore";
import moment from "moment";

export default class ChartUtils {
    static DegreesToRadians(degrees) {
        return degrees * Math.PI / 180;
    }

    static RadiansToDegrees(radians) {
        return radians * 180 / Math.PI;
    }

    static IsBold(radius, interval, tolerance) {
        let boldAt = interval * 5;
        let mod = radius % boldAt;

        return mod < tolerance ||
            boldAt - mod < tolerance;
    }

    static CalculateTextPaddingDegrees(textWidth, padding, radius) {
        // calculate the angle based on the arc length and the radius
        let radians = (textWidth + padding) / radius;
        // divide in half, used on the left and the right
        let halfWidth = radians / 2;
        // convert to degrees
        return this.RadiansToDegrees(halfWidth);
    }

    static ConvertData(data, unit) {
        return data.map(d => {
            return {
                timeStamp: d.timeStamp,
                value: new PressureValue(d.sensor1).getValue(unit, 2)
            }
        })
    }

    static CalculateGraphHeight() {
        if (800 < window.screen.availHeight)
            return 500;
        if (650 < window.screen.availHeight)
            return 400;
        return 300;
    }

    /**
     * @return {string}
     */
    static WhiteOrBlackText(bgColor) {
        let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        let r = parseInt(color.substring(0, 2), 16); // hexToR
        let g = parseInt(color.substring(2, 4), 16); // hexToG
        let b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            '#000' : '#FFF';
    }

    static GenerateGraphSettingsForLog(log) {
        let availableAxes = ['y', 'y2', 'y3', 'y4'];
        let labels = [moduleStore.getString('time')];
        let labelHeaders = [];
        let visibility = [];
        let series = {};

        let nameKeys = Object.keys(log).filter(key => key.startsWith('sensor') && key.endsWith('Name')).sort();

        for (let key of nameKeys) {
            let name = log[key];
            let unit = log[key.replace('Name', 'Unit')];

            if (!!name) {
                labels.push(name);
                labelHeaders.push(unit);
                visibility.push(true);
                series[name] = {axis: availableAxes.shift()};
            }
        }

        return {labels, labelHeaders, visibility, series};
    }

    static GenerateDefaultAxesScales(log, fileData) {
        let pressureMax = -Infinity;
        let temperatureMax = -Infinity;
        let temperatureMin = Infinity;

        let nameKeys = Object.keys(log).filter(key => key.startsWith('sensor') && key.endsWith('Name')).sort();

        for (let key of nameKeys) {
            // let name = log[key];
            let unit = log[key.replace('Name', 'Unit')];
            let seriesIndex = Number(key.substring(6, 7));

            let seriesData = fileData.map(reading => reading[seriesIndex]);
            // if this is a pressure input, change the max value of pressure
            if (PressureValue.isValidUnit(unit)) {
                // find the max pressure
                for (let i = 0; i < seriesData.length; i++) {
                    // if its a triple value array, its min, avg, max, otherwise
                    let reading = Array.isArray(seriesData[i]) ? seriesData[i][1] : seriesData[i];
                    if (pressureMax < reading)
                        pressureMax = reading
                }
            } // if this is a temperature input, change the min and max values of temp
            else if (TemperatureValue.isValidUnit(unit)) {
                // find the min temp
                for (let i = 0; i < seriesData.length; i++) {
                    let reading = Array.isArray(seriesData[i]) ? seriesData[i][1] : seriesData[i];
                    if (reading < temperatureMin)
                        temperatureMin = reading
                }
                // find the max temp
                for (let i = 0; i < seriesData.length; i++) {
                    let reading = Array.isArray(seriesData[i]) ? seriesData[i][1] : seriesData[i];
                    if (temperatureMax < reading)
                        temperatureMax = reading
                }
            } else {
                console.error("HELP INVALID UNIT", unit)
            }
        }

        // CALCULATE PRESSURE RANGE
        let pressureRangeIntervals = [10, 100, 500, 1000, 5000, 10000, 15000];
        // pressure always starts at zero
        let pressureRange = [0, 10];
        // use the nearest pressure range input
        for (let range of pressureRangeIntervals) {
            if (pressureMax < range) {
                pressureRange[1] = range;
                break;
            }
        }

        // CALCULATE TEMPERATURE RANGE
        let temperatureRange = [temperatureMin * 0.9, temperatureMax * 1.1];

        let axisScales = [];

        for (let key of nameKeys) {
            let position = Number(key.substring(6, 7)) - 1;
            let unit = log[key.replace('Name', 'Unit')];

            axisScales[position] = PressureValue.isValidUnit(unit)
                ? pressureRange
                : TemperatureValue.isValidUnit(unit)
                    ? temperatureRange
                    : [0, 100];
        }

        return axisScales;
    }

    static GetDateTimeValueFormatter(format, firstXValue) {
        if (format.toLowerCase() === 'duration')
            return (xValue, granularity) =>  moment.utc(moment.duration(moment(xValue).diff(moment(firstXValue))).asMilliseconds())
                .format( granularity >= 20
                    ? `[${moment(xValue).diff(moment(firstXValue), 'days')} days] HH:mm:ss`
                    : "HH:mm:ss") // granularity defined in dygraphs-tickers.js, 20 is unit of days
        if (format.toLowerCase() === 'us')
            return (xValue) => moment(xValue).format("MM/DD/YY HH:mm:ss")
        if (format.toLowerCase() === 'international')
            return (xValue) => moment(xValue).format("YY-MM-DD HH:mm:ss")

        throw new Error("Unknown Datetime Value Format, see method for valid options");
    }
}
