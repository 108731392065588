import AmetekSignIn from './signin';
import AmetekSignUp from './signup';
import AmetekConfirmSignUp from './confirmSignup';
import AmetekForgotPassword from './forgotPassword';
import AmetekSplashTemplate from "./splash";
import AmetekVerifyContact from "./verify";

export {
    AmetekSignIn,
    AmetekSignUp,
    AmetekConfirmSignUp,
    AmetekForgotPassword,
    AmetekSplashTemplate,
    AmetekVerifyContact
};