import React, { Component } from 'react';
import {Icon, Modal, Button, Message, Form} from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";
import managementConsoleStore from "../stores/ManagementConsoleStore";

export default class CreateOrganizationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading : false,
            modalOpen : false,
            organizationName : '',
            organizationNameError : null,
        };
    }

    open = () => this.setState({ modalOpen: true });
    close = () => this.setState({ modalOpen: false });

    handleInputChange = (e, { name, value }) => this.setState({ [name]: value });

    validateForm = () => {
        let organizationNameError = !this.state.organizationName;

        this.setState({organizationNameError});

        return !organizationNameError;
    };

    onCreate = () => {
        if (this.validateForm()) {
            managementConsoleStore.once('createOrganizationFailedEvent', this.close);
            this.setState({loading : true});
            managementConsoleStore.createOrganization(this.state.organizationName);
        }
    };

    render() {
        return (
            <Modal size='tiny' open={this.state.modalOpen}
                   trigger={
                        <Button animated color='green' fluid
                                onClick={this.open}>
                            <Button.Content visible content={moduleStore.getString('create')}/>
                            <Button.Content hidden>
                                <Icon name='plus' />
                            </Button.Content>
                        </Button>
            }>
                <Modal.Header content={moduleStore.getString('createNewOrganization')}/>
                <Modal.Content>
                    <Form onSubmit={this.onCreate}>
                        <Form.Input label={moduleStore.getString('organizationName')} placeholder={moduleStore.getString('organizationName')}
                                    name='organizationName' onChange={this.handleInputChange}/>

                        <Message error header={moduleStore.getString('error')} content={moduleStore.getString('requiredField')} hidden={!this.state.organizationNameError}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={moduleStore.getString('cancel')}
                            onClick={this.close}
                            disabled={this.state.loading}/>
                    <Button color='green' content={moduleStore.getString('create')}
                            onClick={this.onCreate} disabled={this.state.loading || !this.state.organizationName}
                            loading={this.state.loading}/>
                </Modal.Actions>
            </Modal>
        )
    }
}
