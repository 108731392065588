import EventEmitter from 'events';
import axios from 'axios';
import rg4js from "raygun4js";
import moduleStore from "../settings/ModuleStore";
import {SimpleVersion} from "@ametektci/ametek.stcappscommon";

export const Products = {
    CrystalControl : "Crystal Control",
    DeviceAgent : "Device Agent",
    HPC50 : "HPC50 Series Chassis",
    OfflineClient : "CrystalControlWeb Offline Client"
};

class UpdatesStore extends EventEmitter {
    constructor() {
        super();

        this.updates = {};
    }

    loadLatestUpdate(product) {
        return axios.get(moduleStore.getEnvironmentSetting('updatesUrl')+'/ProductUpdate', {
            params : {
                CurrentVersion : { "Major" : 0, "Minor" : 0, "Patch": "1" },
                NewestVersionOnly : true,
                Product : product,
                ProductHardwareVersion : { "Major" : 1, "Minor" : 0, "Patch": "0"}
            }
        }).then(response => {
            // the latestUpdate
            let latestUpdate = response.data.productUpdates[0];
            // if the update was not able to be found
            if (!latestUpdate || (Object.entries(latestUpdate).length === 0 && latestUpdate.constructor === Object)) {
                // report an error and give up
                console.error('Unable to retrieve update for product ' + product);
                rg4js('send', new Error('Unable to retrieve update for product ' + product));
                return;
            }

            // create a simple version from the versions
            latestUpdate.simpleVersion = new SimpleVersion(latestUpdate.majorVersion, latestUpdate.minorVersion, latestUpdate.patchVersion, latestUpdate.buildNumber);
            // save to the data store
            updatesStore.updates[product] = latestUpdate;
            // announce - this will allow components that have already mounted to get the latest update
            updatesStore.emit('updateAvailableEvent', product, latestUpdate);
        }).catch(error => {
            console.log(error)
            console.error('Unable to retrieve update for product ' + product);
            rg4js('send', error);
        })
    }

    getLatestUpdateVersion(product) {
        return updatesStore.updates[product];
    }

    downloadUpdateFile(product) {
        let previouslyRetrievedUpdate = updatesStore.updates[product];

        if (previouslyRetrievedUpdate)
            window.open(previouslyRetrievedUpdate.packageUri, '_blank');
        else {
            this.loadLatestUpdate(product).then(() => {
                let loadedUpdate = updatesStore.updates[product];
                if (loadedUpdate)
                    window.open(loadedUpdate.packageUri, '_blank');
            })
        }
    }
}

const updatesStore = new UpdatesStore();
export default updatesStore;
