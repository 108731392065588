import EventEmitter from 'events';
import { Auth } from 'aws-amplify';
import moduleStore from "../settings/ModuleStore";
import messageQueue from "../messages/MessageQueue";

class AuthenticationStore extends EventEmitter {
    getAmplifyConfiguration() {
        return {
            Auth: moduleStore.getEnvironmentSetting('amplifyConfig')
        }
    }

    handleAuthStateChange = (authState) => {
        if (authState === 'signedIn')
            this.emit('authenticationSuccessfulEvent')
    };

    async getAccessToken() {
        let currentSession = await Auth.currentSession();
        return currentSession.getAccessToken().getJwtToken();
    }

    signOut = () => {
        Auth.signOut().then(() => authenticationStore.emit('signOutEvent'));
    };

    async changeEmailAddress(newEmailAddress) {
        let user = await Auth.currentAuthenticatedUser();

        let result = await Auth.updateUserAttributes(user, {
            'email': newEmailAddress,
        });

        if (result !== 'SUCCESS')
            messageQueue.sendError('Unable to Update Email Address', 'Email Update Failed');
    }

    isValidPassword(password) {
        // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
        const passwordValidityRegex =  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return password.match(passwordValidityRegex);
    }

    changePassword(oldPassword, newPassword) {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                authenticationStore.emit('passwordChangedEvent');
            })
            .catch(err => {
                authenticationStore.emit('passwordChangedEvent', err.message);
            });
    }
}

const authenticationStore = new AuthenticationStore();
export default authenticationStore;