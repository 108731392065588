import * as React from 'react';
import Auth from '@aws-amplify/auth';
import {AuthPiece} from 'aws-amplify-react';
import { Input, Button, Modal, Message} from 'semantic-ui-react'
import AuthHeader from "./authHeader";
import {signUpWithEmailFields} from "aws-amplify-react/lib/Auth/common/default-sign-up-fields";
import {FormField, InputLabel} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import textResources from "./language/TextResources";
import CustomStyle from "./customStyle";

export default class AmetekSignUp extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['signUp'];
        this.signUp = this.signUp.bind(this);
        this.sortFields = this.sortFields.bind(this);
        this.defaultSignUpFields = signUpWithEmailFields;

        this.state = {
            errorMessage : null
        }
    }

    validate() {
        const invalids = [];
        this.signUpFields.map((el) => {          
            if (el.required && !this.inputs[el.key]) {
              el.invalid = true;
              invalids.push(el.label);
            } else {
              el.invalid = false;
            }        
          return null;
        });
        return invalids;
    }

    sortFields() {
        this.signUpFields = this.defaultSignUpFields.filter(x => x.key !== 'phone_number');

        let nameField = {
            displayOrder : 3,
            key: 'name',
            label : textResources.Strings.name,
            placeholder: textResources.Strings.name,
            required : true,
            type : 'text'
        };

        this.signUpFields.push(nameField);
    }

    signUp() {
        const fieldsWithErrors = this.validate();
        if (fieldsWithErrors && fieldsWithErrors.length > 0) {
            // translate the field names to the appropriate language
            let translatedFieldsWithErrors = fieldsWithErrors.map(field => textResources.Strings[field.toLowerCase()] || '');
            // report all the invalid fields
            let errorMessage = textResources.Strings.enterValidValues  + ' : ' + translatedFieldsWithErrors.join(', ');
            this.setState({errorMessage});
            return;
        }
        if (!Auth || typeof Auth.signUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }  

        const signup_info = {
            username: this.inputs.email.toLowerCase(),
            password: this.inputs.password,
            attributes: {
                email : this.inputs.email.toLowerCase(),
                name: this.inputs.name,
            }
        };
        
        Auth.signUp(signup_info).then((data) => {
            this.triggerAuthEvent({
                type: 'error',
                data: textResources.Strings.codeSent
            });

            this.changeState('confirmSignUp', {
                username: data.user.username, 
                password: this.inputs.password
            });
        })
        .catch(error => {
            this.setState({errorMessage : error.message})
        });
    }

    showComponent(theme) {
        this.sortFields();

        let inviteCodeSpecified = false;

        let url = new URL(window.location.href);
        if (url.searchParams.has('account') && url.searchParams.has('joinOrganizationCode') ) {
            this.inputs.email =  url.searchParams.get('account');
            inviteCodeSpecified = true;
        }

        return (
            <Modal open={true} size='small'>
                <Modal.Content>
                    <AuthHeader title={textResources.Strings.createNewAccount}/>

                    { inviteCodeSpecified ? <Message content={textResources.Strings.signInOrCreate}
                                                     positive /> :
                        null
                    }

                    { this.signUpFields.map((field) => {
                            return (
                                <FormField theme={theme} style={CustomStyle.FormField} key={field.key}>
                                    <InputLabel theme={theme} style={CustomStyle.InputLabel}>{field.label}</InputLabel>
                                    <Input fluid
                                           autoFocus={
                                               this.signUpFields.findIndex((f) => {
                                                   return f.key === field.key
                                               }) === 0
                                           }
                                           placeholder={field.label}
                                           theme={theme}
                                           type={field.type}
                                           name={field.key}
                                           key={field.key}
                                           value={ inviteCodeSpecified ? this.inputs[field.key] : undefined}
                                           disabled={field.key === 'email' && inviteCodeSpecified}
                                           onChange={this.handleInputChange}
                                    />
                                </FormField>
                            )
                        })
                    }

                    <Message error content={this.state.errorMessage}
                             hidden={!this.state.errorMessage}/>

                    <Button
                        color='red'
                        size='large'
                        type='submit'
                        floated='right'
                        content={textResources.Strings.createNewAccount}
                        onClick={this.signUp}
                    />
                    <Button basic
                            content={textResources.Strings.backToSignIn}
                            onClick={() => this.changeState('signIn')}/>

                </Modal.Content>
            </Modal>
        );
    }

}