import React, { Component } from 'react'
import {Button, Grid, Header, Icon, Placeholder, Segment} from 'semantic-ui-react'
import Utils from "../utils/Utils";
import moduleStore from "../settings/ModuleStore";
import  AvailableCards from "./AvailableCards";
import managementConsoleStore from "../stores/ManagementConsoleStore";

export default class CardSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading : true,
            paymentCards : [],
            selectedCard : {},
            selectionOpen : false
        };
    }

    componentDidMount() {
        managementConsoleStore.once('paymentCardsLoadedEvent', (paymentCards) => this.setState({
            loading : false,
            paymentCards,
            selectedCard : paymentCards.find(c => c.default) || 1 <= paymentCards.length ? paymentCards[0] : {}
        }, () => {
            this.props.onCardSelection(this.state.selectedCard)
        }));

        managementConsoleStore.getPaymentCards();
    }

    open = () => this.setState({selectionOpen : true});
    close = () => this.setState({selectionOpen : false});

    onCardSelection = (cardType, cardLastFourDigits) => {
        this.setState(prevState => ({
            selectedCard : prevState.paymentCards.find(c => c.creditCard.cardType === cardType && c.creditCard.cardLastFourDigits === cardLastFourDigits) || {}
        }), () => {
            this.props.onCardSelection(this.state.selectedCard)
        });
    };

    render() {
        return (
            <Segment>
                {this.state.loading ?
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    this.state.paymentCards.length === 0 ?
                        <Grid columns={2} verticalAlign='middle'>
                            <Grid.Column>
                                <Header content={moduleStore.getString('noCardsOnFile')} />
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <Button href={moduleStore.getEnvironmentSetting('managementConsoleUrl')} target='_blank'
                                        content={moduleStore.getString('manageCards')} color='grey' />
                            </Grid.Column>
                        </Grid>
                        :
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={2}>
                                <Icon name={Utils.GetCreditCardIcon(this.state.selectedCard.creditCard.cardType)} size='big' />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Header content={`${this.state.selectedCard.billingContactInfo.firstName} ${this.state.selectedCard.billingContactInfo.lastName}`} />
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Header size='small'>
                                    **** **** **** {this.state.selectedCard.creditCard.cardLastFourDigits}
                                    <Header.Subheader>
                                        Exp. {this.state.selectedCard.creditCard.expirationMonth}/{this.state.selectedCard.creditCard.expirationYear}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>

                            <Grid.Column width={3}>
                                <AvailableCards paymentCards={this.state.paymentCards} onCardSelection={this.onCardSelection} />
                            </Grid.Column>
                        </Grid>
                }
            </Segment>
        )
    }
}
