import * as React from 'react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {Button, Input, Message, Modal} from "semantic-ui-react";
import AuthHeader from "./authHeader";
import {AuthPiece} from 'aws-amplify-react';
import {FormField, InputLabel} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import textResources from "./language/TextResources";
import CustomStyle from "./customStyle";
const logger = new Logger('ConfirmSignUp');

class AmetekConfirmSignUp extends AuthPiece {
    constructor(props) {
        super(props);       

        this._validAuthStates = ['confirmSignUp'];
        this.confirm = this.confirm.bind(this);
        this.resend = this.resend.bind(this);
        this.state = {};
    }

    confirm() {
        const username = this.usernameFromAuthData() || this.inputs.username.toLowerCase();
        const { code } = this.inputs;
        if (!Auth || typeof Auth.confirmSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        this.setState({ loading: true });
        Auth.confirmSignUp(username, code)
            .then(async () => {
                const { password } = this.props.authData;
                const user = await Auth.signIn(username, password);
                this.changeState('signedIn', user);
            })
            .catch(err => this.error(err))
            .finally(() => this.setState({ loading: false }));
    }

    resend() {
        const username = this.usernameFromAuthData() || this.inputs.username.toLowerCase();
        if (!Auth || typeof Auth.resendSignUp !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.resendSignUp(username)
            .then(() => {
                logger.debug('code resent');
                this.triggerAuthEvent({
                    type: 'error',
                    data: textResources.Strings.codeResent
                });
            })
            .catch(err => this.error(err));
    }

    showComponent(theme) {
        const username = this.usernameFromAuthData();

        return (
            <Modal open={true} size='small'>
                <Modal.Content>
                    <AuthHeader title={textResources.Strings.verifyEmailAddress} />

                    { !! username ?
                        <Message content={textResources.Strings.verificationCodeSent.replace("###", username)} positive/>
                        : null
                    }

                    <FormField theme={theme} style={CustomStyle.FormField}>
                        <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.email}</InputLabel>
                        <Input fluid
                            placeholder={textResources.Strings.email}
                            key="username"
                            name="username"
                            onChange={this.handleInputChange}
                            disabled={!!username}
                            value={username ? username : ""}
                        />
                    </FormField>

                    <FormField theme={theme} style={CustomStyle.FormField}>
                        <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.verificationCode}</InputLabel>
                        <Input fluid
                            autoFocus
                            placeholder={textResources.Strings.enterVerificationCode}
                            theme={theme}
                            key="code"
                            name="code"
                            autoComplete="off"
                            onChange={this.handleInputChange}
                        />
                    </FormField>

                    <Button
                        color='red'
                        size='large'
                        type='submit'
                        floated='right'
                        loading={this.state.loading}
                        onClick={this.confirm}
                        content={textResources.Strings.verify }
                    />

                    <Button
                        size='large'
                        floated='right'
                        content={textResources.Strings.resendCode}
                        onClick={this.resend}/>

                    <Button basic
                            content={textResources.Strings.backToSignIn}
                            onClick={() => this.changeState('signIn')}/>
                </Modal.Content>
            </Modal>
        );
    }
}

export default AmetekConfirmSignUp;
