import languageStrings from './languageStrings';

const SupportedLanguages = [
    'english',
    'french',
    'german',
    'spanish',
    'chinese' // simplified
];

const LanguageLocalStorageKey = 'selectedLanguage';

class TextResources {
    constructor() {
        this.language = this.determineLanguage();
        // set the strings to use
        this.Strings = languageStrings[this.language];
    }

    determineLanguage() {
        // check to see if an option has already been saved
        let saved = localStorage.getItem(LanguageLocalStorageKey);
        if (saved) return saved;

        // otherwise use what the browser uses
        let browserLanguage = window.navigator.language;

        if (browserLanguage.startsWith('en'))
            return 'english';
        if (browserLanguage.startsWith('fr'))
            return 'french';
        if (browserLanguage.startsWith('de'))
            return 'german';
        if (browserLanguage.startsWith('es'))
            return 'spanish';
        if (browserLanguage.startsWith('zh'))
            return 'chinese';

        // if its not a supported language, use english
        return 'english';
    }

    getCurrentLanguage() {
        return this.language;
    }

    setCurrentLanguage(language) {
        if (! SupportedLanguages.some(l => l === language))
            throw new Error("Language not supported");

        this.language = language;
        //save to local storage
        localStorage.setItem(LanguageLocalStorageKey, language);
        //set strings
        this.Strings = languageStrings[this.language];
        // reload page to see changes
        window.location.reload();
    }

    getSupportedLanguages() {
        return SupportedLanguages;
    }

}

const textResources = new TextResources();
export default textResources;