import React, { Component } from 'react';
import {Icon, Modal, Button, Form, Message} from 'semantic-ui-react';
import managementConsoleStore from "../stores/ManagementConsoleStore";
import moduleStore from "../settings/ModuleStore";

export default class JoinOrganizationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen : false,
            loading : false,
            accessCode : '',
            invalidCode : false
        }
    }

    open = () => this.setState({ modalOpen: true, invalidCode : false, accessCode : '' });
    close = () => this.setState({ modalOpen: false });

    handleInputChange = (e, { name, value }) => this.setState({ [name]: value });

    onJoin = () => {
        this.setState({loading : true});

        managementConsoleStore.once('joinOrganizationFailedEvent', () => {
            this.setState({loading : false, invalidCode : true});
        });

        managementConsoleStore.joinOrganization(this.state.accessCode);
    };

    render() {
        return (
            <Modal size='tiny' open={this.state.modalOpen}
                   trigger={
                       <Button animated color='blue' fluid
                               onClick={this.open}>
                       <Button.Content visible content={moduleStore.getString('join')}/>
                           <Button.Content hidden>
                               <Icon name='arrow right' />
                           </Button.Content>
                       </Button>
                   }>
                <Modal.Header content={moduleStore.getString('joinExistingOrganization')}/>
                <Modal.Content>
                    <Form onSubmit={this.onJoin}>
                        <Message content={moduleStore.getString('contactAdminForCode')}/>

                        <Form.Input label={moduleStore.getString('organizationAccessCode')} placeholder={moduleStore.getString('organizationAccessCode')}
                                    name='accessCode' onChange={this.handleInputChange}/>
                    </Form>

                    <Message error content={moduleStore.getString('invalidOrganizationCode')} hidden={!this.state.invalidCode}/>

                </Modal.Content>
                <Modal.Actions>
                    <Button content={moduleStore.getString('close')}
                            onClick={this.close} disabled={this.state.loading}/>
                    <Button color='blue' content={moduleStore.getString('join')}
                            onClick={this.onJoin} disabled={this.state.loading || !this.state.accessCode}
                            loading={this.state.loading}/>
                </Modal.Actions>
            </Modal>
        )
    }
}
