import moment from "moment";
import moduleStore from "../settings/ModuleStore";

const TimeFormats = ["MM/DD/YYYY HH:mm:ss", "YYYY-MM-DDTHH:mm:ss"];

export default class DateUtils {

    static FormatDate(date, defaultValue=null) {
        return date ?
            moment(date, TimeFormats).format('LL') :
            (defaultValue || moduleStore.getString('unknown'));
    }

    static DueStatus(date, warningPeriodDays) {
        let daysUntilDue = moment.duration(moment(date, TimeFormats).diff(moment.utc())).asDays();

        if (isNaN(daysUntilDue) || daysUntilDue <= 0)
            return 'pastDue';
        else if (daysUntilDue < warningPeriodDays)
            return 'warn';

        return 'ok';
    }

    static FormatMonthYear = function (date) {
        return moment.utc(date, TimeFormats).local().format("MMMM, YYYY");
    };

    static CurrentTimeLocale() {
        return moment().format(TimeFormats[0]);
    }

    static CurrentTimeUtc() {
        return moment.utc().format(TimeFormats[0]);
    }

    static FormatFileSafeTimeStamp(date) {
        return moment(date, TimeFormats).format('YYYY-MM-DD_HH-mm-ss')
    }

    static FormatDatePicker(date) {
        return moment(date, TimeFormats).format('MM/DD/YYYY HH:mm:ss')
    }

    static FormatMillisTime(millis) {
        if (!millis) return '';
        return moment(millis, 'x').format(TimeFormats[0])
    }

    static UtcToLocalDate(date) {
        return moment.utc(date, TimeFormats).local().format(TimeFormats[0]);
    }

    static SortLogsByName (a, b) {
        if (a.name && b.name) return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        if (a.name)           return -1;
        if (b.name)           return 1;

        return DateUtils.SortLogsByTimestamps(a, b);
    }

    static SortLogsByTimestamps(a, b) {
        return  moment(b.startTime, TimeFormats).diff(moment(a.startTime, TimeFormats));
    }

    static DaysUntilDate(date) {
        return moment.utc(date, TimeFormats).startOf('day').diff(moment.utc().startOf('day'), 'days');
    }

    static AddDaysFromNow(days) {
        return moment().add(days, 'days').format(TimeFormats[0])
    }

    static HasPast(date) {
        return moment(date, TimeFormats).diff(moment.utc()) < 0;
    }

    static DateWithInDays(date, days) {
        return moment.utc().diff(moment.utc(date, TimeFormats), 'days') < days;
    }

    static FormatTimeSince(date) {
        if ( ! date)
            return moduleStore.getString('never');

        let diff = moment.duration(moment.utc().diff(moment(date, TimeFormats)));

        if (diff.asHours() < 1)
            return moduleStore.getString('minutesAgo');
        else if (diff.asDays() < 1)
            return moduleStore.getString('xHoursAgo').replace('###', Math.floor(diff.asHours()));

        return DateUtils.FormatDate(date);
    }
}
