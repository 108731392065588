import languageStrings from './languageStrings';

class ModuleStore {
    setLanguage(language) {
        this.language = language;
    }

    setEnvironment(env) {
        this.env = env;
    }

    getString(key) {
        if ( ! this.language)
            throw new Error("Language has not been set for the StcAppsCommon library. use moduleStore.setLanguage() to specify the settings")

        let value = languageStrings[this.language][key];

        if ( ! value)
            console.warn('StcAppsCommon : Could not find translated string for key : ', key )

        return value;
    }

    getEnvironmentSetting(key) {
        if ( ! this.env)
            throw new Error("Environment settings have not been set for the StcAppsCommon library. use moduleStore.setEnvironment() to specify the settings")

        let value = this.env[key];

        if ( ! value)
            console.warn('StcAppsCommon : Could not find environment variable for key : ', key)

        return value;
    }
}

const moduleStore = new ModuleStore();
export default moduleStore;

export const SetEnvironment = (environment) => moduleStore.setEnvironment(environment);
export const SetLanguage = (language) => moduleStore.setLanguage(language);
