import React, { Component } from 'react';
import {Divider, Grid, Header, Image} from 'semantic-ui-react';
import ametek from "./ametekSTC_black.svg";
import LanguageSelection from "./language/LanguageSelection";

export default class AuthHeader extends Component {
    render() {
        return (
            <span>
                <Grid divided>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width={4}>
                            <Image src={ametek}/>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Header content={this.props.title}/>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <LanguageSelection />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider/>
            </span>
        )
    }
}