import * as React from 'react';
import Auth from '@aws-amplify/auth';
import {AuthPiece} from 'aws-amplify-react';
import {Message, Modal, Segment, Button, Input} from "semantic-ui-react";
import textResources from "./language/TextResources";
import AuthHeader from "./authHeader";
import {FormField, InputLabel} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import CustomStyle from "./customStyle";

export default class AmetekVerifyContact extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['verifyContact'];

        this.state = {
            codeSent : false,
            loading : false,
            code : '',
            emailSentTo : '',
            codeError : ''
        };
    }

    sendCode = () => {
        this.setState({loading : true});

        Auth.verifyCurrentUserAttribute('email')
          .then(data => {
              this.setState({codeSent : true, loading : false})
          }).catch(err => {
              console.error('error while sending verification code', err)
          })
    };

    submitVerificationCode = () => {
        Auth.verifyCurrentUserAttributeSubmit('email', this.state.code.replace(/ /g,''))
            .then(data => {
                this.changeState('signedIn')
            }).catch(err => {
                this.setState({codeError : textResources.Strings.invalidCode});
                console.error('error while submitting verification code', err)
            })
    };

    handleChange = (e, {name, value}) => this.setState({[name] : value});

    showComponent(theme) {
        return (
            <Modal open={true} size='small'>
                <Modal.Content>
                    <AuthHeader title={textResources.Strings.verifyEmailAddress}/>

                    {this.state.codeSent ?
                        <span>
                            <Message content={textResources.Strings.verificationCodeSent.replace("###", this.props.authData.attributes.email)}/>

                            <FormField theme={theme} style={CustomStyle.FormField}>
                                <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.verificationCode}</InputLabel>
                                <Input fluid
                                       autoFocus
                                       placeholder={textResources.Strings.enterVerificationCode}
                                       theme={theme}
                                       key="code"
                                       name="code"
                                       autoComplete="off"
                                       onChange={this.handleChange}
                                />
                            </FormField>

                            {this.state.codeError ? <Message error content={this.state.codeError}/> : null }

                            <Segment basic textAlign='right'>
                                <Button
                                    color='red'
                                    size='large'
                                    loading={this.state.loading}
                                    onClick={this.submitVerificationCode}
                                    content={textResources.Strings.verify }
                                />
                            </Segment>

                        </span>
                        :
                        <span>
                            <Message content={textResources.Strings.verifyEmailAddress} />

                            <Segment basic textAlign='right'>
                                <Button content={textResources.Strings.sendCode} size='large' color='red'
                                        onClick={this.sendCode} disabled={this.state.loading} loading={this.state.loading}/>
                            </Segment>
                        </span>
                    }
                </Modal.Content>
            </Modal>
        );
    }
}
