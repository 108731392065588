import React, { Component } from 'react'
import {Button, Grid, Header, Icon, Modal, Segment} from 'semantic-ui-react'
import Utils from "../utils/Utils";
import moduleStore from  "../settings/ModuleStore";

export default class AvailableCards extends Component {
    constructor(props) {
        super(props);

        this.state = { open : false };
    }

    open = () => this.setState({open : true});
    close = () => this.setState({open : false});

    selectCard = (cardType, cardLastFourDigits) => {
        this.props.onCardSelection(cardType, cardLastFourDigits);
        this.close();
    };

    render() {
        return (
            <Modal size='small' open={this.state.open}
                   trigger={ <Button fluid onClick={this.open} content={moduleStore.getString('change')} /> }>
                <Modal.Header>{moduleStore.getString('selectPaymentCard')}</Modal.Header>
                <Modal.Content scrolling>
                    {this.props.paymentCards.map(card =>
                        <Segment key={card.creditCard.cardType+card.creditCard.cardLastFourDigits}>

                            <Grid verticalAlign='middle'>
                                <Grid.Column width={2}>
                                    <Icon name={Utils.GetCreditCardIcon(card.issuer)} size='big' />
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Header content={`${card.billingContactInfo.firstName} ${card.billingContactInfo.lastName}`} />
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Header size='small'>
                                        **** **** **** {card.creditCard.cardLastFourDigits}
                                        <Header.Subheader>
                                            Exp. {card.creditCard.expirationMonth}/{card.creditCard.expirationYear}}
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Button content={moduleStore.getString('select')} color='blue' onClick={() => this.selectCard(card.creditCard.cardType, card.creditCard.cardLastFourDigits)}/>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    )}

                </Modal.Content>
                <Modal.Actions>
                    <Button href={moduleStore.getEnvironmentSetting('managementConsoleUrl')} target='_blank' content={moduleStore.getString('manageCards')} color='grey' />
                    <Button onClick={this.close} content={moduleStore.getString('cancel')} />
                </Modal.Actions>
            </Modal>
        )
    }
}
