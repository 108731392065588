import React, { Component } from 'react';
import {Button, Grid, Header, Divider, Segment} from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";
import CardSelection from "./CardSelection";
import managementConsoleStore from "../stores/ManagementConsoleStore";

export default class CheckoutSubscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentCard : {},
            loading : false
        }
    }

    onCardSelection = (paymentCard) => this.setState({paymentCard});

    onCheckout = () => {
        this.setState({loading : true});

        managementConsoleStore.once('subscriptionStartedEvent', () => {
            this.setState({loading : false})
        });

        managementConsoleStore.startSubscription(this.props.autoAssign, this.props.tier, this.props.plan.billingPlan,
            this.state.paymentCard.creditCard.cardType, this.state.paymentCard.creditCard.cardLastFourDigits);
    };

    render() {
        return (
            <div>
                <Header dividing content={moduleStore.getString('subscription')} />
                <Segment>
                    <Grid>
                        <Grid.Column width={10}>
                            <Header content={moduleStore.getString(this.props.plan.descriptionKey)} />
                        </Grid.Column>
                        <Grid.Column width={6} textAlign='right'>
                            <Header content={'$'+this.props.plan.price} size='large' />
                        </Grid.Column>
                    </Grid>
                </Segment>

                <Header dividing content={moduleStore.getString('paymentMethod')} />
                <CardSelection onCardSelection={this.onCardSelection} />

                <Divider hidden/>

                <Segment basic textAlign='right'>
                    <Button size='large' color='green' icon='lock' content={moduleStore.getString('checkout')} onClick={this.onCheckout}
                            loading={this.state.loading} disabled={this.state.loading || !this.state.paymentCard.creditCard} />
                </Segment>

            </div>
        )
    }
}
