import * as React from 'react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { validate}  from "email-validator";
import {AmplifyTheme} from "aws-amplify-react";
import {AuthPiece} from 'aws-amplify-react';
import {FormField, InputLabel} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import {Button, Input, Message, Modal} from "semantic-ui-react";
import AuthHeader from "./authHeader";
import textResources from "./language/TextResources";
import CustomStyle from "./customStyle";

const logger = new Logger('ForgotPassword');

export default class AmetekForgotPassword extends AuthPiece {
    constructor(props) {
        super(props);

        this.send = this.send.bind(this);
        this.submit = this.submit.bind(this);

        this._validAuthStates = ['forgotPassword'];
        this.state = { delivery: null, error : null };
    }

    send() {        
        const emailAddress = this.inputs.email.toLowerCase();

        if (!validate(emailAddress)) {
            this.setState({error : textResources.Strings.enterValidEmail});
            return;
        }

        if (!Auth || typeof Auth.forgotPassword !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.forgotPassword(emailAddress)
            .then(data => {
                logger.debug(data)
                this.setState({ delivery: data.CodeDeliveryDetails });
                this.triggerAuthEvent({
                    type: 'error',
                    data: textResources.Strings.codeSent
                });
            })
            .catch(error => this.setState({error : error.message}));
    }

    submit() {
        const { code, password, email } = this.inputs;
        const username = email.toLowerCase();
        
        if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }

        this.setState({ loading: true });
        Auth.forgotPasswordSubmit(username, code, password)
            .then(data => {
                logger.debug(data);
                this.changeState('signIn');
                this.setState({ delivery: null });
                this.triggerAuthEvent({
                    type: 'error',
                    data: textResources.Strings.passwordChanged
                });
            })
            .catch(error => this.setState({error}))
            .finally(() => { this.setState({ loading: false })});
    }

    sendView() {
        const theme = this.props.theme || AmplifyTheme;
        return (
            <div>
                <FormField theme={theme} style={CustomStyle.FormField}>
                    <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.email}</InputLabel>
                    <Input fluid
                        placeholder={textResources.Strings.email}
                        theme={theme}
                        key="email"
                        type="email"
                        name="email"
                        onChange={this.handleInputChange}
                    />
                </FormField>

                <Button floated='right'
                        color='red'
                        size='large'
                        onClick={this.send}
                        content={textResources.Strings.sendCode}/>
            </div>
        );
    }

    submitView() {
        const theme = this.props.theme || AmplifyTheme;
        return (
            <div>
                <FormField theme={theme} style={CustomStyle.FormField}>
                    <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.authorizationCode}</InputLabel>
                    <Input fluid
                        placeholder={textResources.Strings.authorizationCode}
                        key="code"
                        name="code"
                        autoComplete="off"
                        onChange={this.handleInputChange}
                    />
                </FormField>
                <FormField theme={theme} style={CustomStyle.FormField}>
                    <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.newPassword}</InputLabel>
                    <Input fluid
                        placeholder={textResources.Strings.newPassword}
                        type="password"
                        key="password"
                        name="password"
                        onChange={this.handleInputChange}
                />
                </FormField>

                <Button floated='right'
                        color='red'
                        size='large'
                        onClick={this.submit}
                        disabled={this.state.loading}
                        content={ this.state.loading ? textResources.Strings.resetting : textResources.Strings.submit} />
            </div>
        );
    }

    showComponent(theme) {
        return (
            <Modal open={true} size='small'>
                <Modal.Content>
                    <AuthHeader title={textResources.Strings.passwordReset}/>

                    { this.state.delivery ? this.submitView() : this.sendView() }

                    <Button basic
                            content={textResources.Strings.backToSignIn}
                            onClick={() => this.changeState('signIn')}/>

                    <Message error header={textResources.Strings.error}
                             hidden={!this.state.error}
                             content={this.state.error} />

                </Modal.Content>
            </Modal>
        );
    }
}
