import React, { Component } from 'react';
import {Divider, Grid, Header, Icon, Modal, Button, Segment, Container} from 'semantic-ui-react';
import CreateOrganizationModal from "./CreateOrganizationModal";
import JoinOrganizationModal from "./JoinOrganizationModal";
import authenticationStore from "../stores/AuthenticationStore";
import moduleStore from "../settings/ModuleStore";

export default class UserRegistration extends Component {
    render() {
        return (
            <Modal open={true} size='small'>
                <Modal.Header content={moduleStore.getString('userRegistration')}/>
                <Modal.Content>
                    <Container content={moduleStore.getString('organizationManagementDescription')} />

                    <Divider/>

                    <Segment>
                        <Grid stackable>
                            <Grid.Column width={10}>
                                <Header as='h4'>
                                    <Icon name='user plus' />
                                    <Header.Content>
                                        {moduleStore.getString('createNewOrganization')}
                                        <Header.Subheader>{moduleStore.getString('createNewOrganization')}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <CreateOrganizationModal />
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    <Segment>
                        <Grid stackable>
                            <Grid.Column width={10}>
                                <Header as='h4' >
                                    <Icon name='group' />
                                    <Header.Content>
                                        {moduleStore.getString('joinExistingOrganization')}
                                        <Header.Subheader>{moduleStore.getString('joinExistingOrganizationDescription')}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <JoinOrganizationModal />
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    <Segment>
                        <Grid stackable>
                            <Grid.Column width={10}>
                                <Header as='h4'>
                                    <Icon name='left arrow' />
                                    <Header.Content>
                                        {moduleStore.getString('signOut')}
                                        <Header.Subheader>{moduleStore.getString('signOut')}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Button animated color='red' fluid onClick={authenticationStore.signOut}>
                                    <Button.Content visible content={moduleStore.getString('signOut')}/>
                                    <Button.Content hidden>
                                        <Icon name='arrow left' />
                                    </Button.Content>
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Segment>

                </Modal.Content>
            </Modal>
        )
    }
}
