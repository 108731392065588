import React, { Component } from 'react';
import { Dimmer, Loader} from 'semantic-ui-react';

export default class LoadingPage extends Component {
    render() {
        return (
            <Dimmer style={{height:'100%'}} active inverted>
                <Loader active inline='centered' size='massive' />
            </Dimmer>
        )
    }
}
