import React, { Component } from 'react';
import { Button, Header, Card, Label } from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";

const _priceTiers = {
    'CrystalControlWeb Standard' : {
        month : {
            descriptionKey: 'standardBilledMonthly',
            discount : 0,
            billingPlan : 'MONTHLY',
            price : 25
        },
        year : {
            descriptionKey: 'standardBilledYearly',
            discount : 45,
            billingPlan : 'ANNUALLY',
            price : 170
        },
        threeYear: {
            descriptionKey: 'standardBilledThreeYear',
            discount : 65,
            billingPlan : 'EVERY 3 YEARS',
            price : 408
        }
    },
    'CrystalControlWeb Advanced' : {
        month : {
            descriptionKey: 'advancedBilledMonthly',
            discount : 0,
            billingPlan : 'MONTHLY',
            price : 30
        },
        year : {
            descriptionKey: 'advancedBilledYearly',
            discount : 39,
            billingPlan : 'ANNUALLY',
            price : 220
        },
        threeYear: {
            descriptionKey: 'advancedBilledThreeYear',
            discount : 51,
            billingPlan : 'EVERY 3 YEARS',
            price : 528
        }
    }
};

export default class BillingPlanSelection extends Component {
    render() {
        return (
            <Card.Group itemsPerRow={3}>
                <Card>
                    <Card.Content extra>
                        <Card.Header>{moduleStore.getString('monthly')}</Card.Header>
                        <Card.Meta>{moduleStore.getString('mostFrequentBilling')}</Card.Meta>
                    </Card.Content>
                    <Card.Content textAlign='center'>
                        <Header content={'$' + _priceTiers[this.props.tier].month.price} subheader={'/ '+moduleStore.getString('month')} size='huge' />
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid size='small' content={moduleStore.getString('select')} onClick={() => this.props.onPlanSelection(_priceTiers[this.props.tier].month)}/>
                    </Card.Content>
                </Card>

                <Card color='green'>
                    <Card.Content extra>
                        <Card.Header>{moduleStore.getString('annual')}</Card.Header>
                        <Card.Meta>{moduleStore.getString('mostPopularOption')}</Card.Meta>
                    </Card.Content>
                    <Card.Content textAlign='center'>
                        <Header content={'$' + _priceTiers[this.props.tier].year.price} subheader={'/ '+moduleStore.getString('year')} size='huge'/>
                        <Label content={_priceTiers[this.props.tier].year.discount + moduleStore.getString('savingsComparedToMonthly')} size='tiny'/>
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid size='small' content={moduleStore.getString('select')} color='green' onClick={() => this.props.onPlanSelection(_priceTiers[this.props.tier].year)}/>
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content extra>
                        <Card.Header>{moduleStore.getString('threeYears')}</Card.Header>
                        <Card.Meta>{moduleStore.getString('leastFrequentBilling')}</Card.Meta>
                    </Card.Content>
                    <Card.Content textAlign='center'>
                        <Header content={'$' + _priceTiers[this.props.tier].threeYear.price} subheader={'/ '+moduleStore.getString('threeYears')} size='huge'/>
                        <Label content={_priceTiers[this.props.tier].threeYear.discount + moduleStore.getString('savingsComparedToMonthly')} size='tiny'/>
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid size='small' content={moduleStore.getString('select')} onClick={() => this.props.onPlanSelection(_priceTiers[this.props.tier].threeYear)}/>
                    </Card.Content>
                </Card>

            </Card.Group>
        )
    }
}
