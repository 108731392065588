import React, { Component } from 'react';
import {Button, Grid, Header, Divider, Segment, List, Icon} from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";

const _standardFeatures = [
    'uploadDataFromPc',
    'customizedGraphing',
    'loggedDataManagement',
    'securePdfExport',
    'deviceConfigurationFeature',
];

const _advancedFeatures = [
    'offlineWindowsApp'
];

export default class SubscriptionTierSelection extends Component {
    render() {
        return (
            <Grid columns={2}>
                <Grid.Column>
                    <Segment color='blue'>
                        <Header content={moduleStore.getString('standard')} size='huge' color='blue' />
                        <Divider/>

                        <Header dividing size='small' color='grey' content={moduleStore.getString('features')} />

                        <List size='large'>
                            {_standardFeatures.map(feature => (
                                <List.Item key={feature}>
                                    <Icon name='caret square right' color='blue' />
                                    <List.Content content={moduleStore.getString(feature)} />
                                </List.Item>
                            ))}
                            {_advancedFeatures.map(feature => (
                                <List.Item key={feature} disabled>
                                    <Icon name='caret square right' color='grey' />
                                    <List.Content content={moduleStore.getString(feature)} />
                                </List.Item>
                            ))}
                        </List>

                        <Divider/>
                        <Button animated color='blue' size='small' fluid
                                onClick={() => this.props.onTierSelection('CrystalControlWeb Standard')}>
                            <Button.Content visible >
                                {moduleStore.getString('subscribe')}
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name='shopping cart' />
                            </Button.Content>
                        </Button>

                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment color='red'>
                        <Header content={moduleStore.getString('advanced')} size='huge' color='red' />
                        <Divider/>

                        <Header dividing size='small' color='grey' content={moduleStore.getString('features')} />

                        <List size='large'>
                            {_standardFeatures.map(feature => (
                                <List.Item key={feature}>
                                    <Icon name='caret square right' color='blue' />
                                    <List.Content content={moduleStore.getString(feature)} />
                                </List.Item>
                            ))}
                            {_advancedFeatures.map(feature => (
                                <List.Item key={feature}>
                                    <Icon name='caret square right' color='red' />
                                    <List.Content content={moduleStore.getString(feature)} />
                                </List.Item>
                            ))}
                        </List>

                        <Divider/>
                        <Button animated color='red' size='small' fluid
                                onClick={() => this.props.onTierSelection('CrystalControlWeb Advanced')}>
                            <Button.Content visible >
                                {moduleStore.getString('subscribe')}
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name='shopping cart' />
                            </Button.Content>
                        </Button>

                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}
