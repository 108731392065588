import EventEmitter from 'events';
import axios from 'axios';
import moduleStore from "../settings/ModuleStore";
import messageQueue from "../messages/MessageQueue";
import authenticationStore from "./AuthenticationStore";

class ManagementConsoleStore extends EventEmitter {

    async getPaymentCards() {
        if (managementConsoleStore.paymentCards)
            managementConsoleStore.emit('paymentCardsLoadedEvent', managementConsoleStore.paymentCards)
        else  {
            axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+'/PaymentCards', {
                JwtRequestToken : await authenticationStore.getAccessToken()
            }).then(response => {
                managementConsoleStore.paymentCards = response.data.paymentCards || [];
                managementConsoleStore.emit('paymentCardsLoadedEvent', managementConsoleStore.paymentCards);
            }).catch(error => {
                console.error('error while loading payment cards', error);
                managementConsoleStore.emit('paymentCardsLoadedEvent', []);
            })
        }
    }

    async startSubscription(autoAssign, product, billingPlan, cardType, cardLastFourDigits) {
        axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+'/Billing/CreateSubscription', {
            JwtRequestToken : await authenticationStore.getAccessToken(),
            AssignToCallingUser : autoAssign !== false,
            Product : product,
            BillingPlan : billingPlan,
            CardType : cardType,
            CardLastFourDigits : cardLastFourDigits
        }).then(response => {
            managementConsoleStore.emit('subscriptionStartedEvent', response.data.success, response.data.subscriptionSeat.stripeProductId)
        }).catch(error => {
            console.error(error);
            messageQueue.sendError(moduleStore.getString('errorCreatingSubscription'));
        })
    }

    async joinOrganization(joinOrganizationCode) {
        axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+"/JoinOrganization", {
            JwtRequestToken : await authenticationStore.getAccessToken(),
            JoinOrganizationCode : joinOrganizationCode
        }).then((response) => {
            if (response.data.success) {
                managementConsoleStore.emit('organizationJoinedEvent')
            }
            else {
                managementConsoleStore.emit('joinOrganizationFailedEvent');
            }
        }).catch ((error) => {
            messageQueue.sendError(error.message, 'Error while attempting to join organization');
        })
    }

    async createOrganization(organizationName) {
        axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+"/CreateOrganization", {
            JwtRequestToken : await authenticationStore.getAccessToken(),
            OrganizationName : organizationName,
        }).then((response) => {
            if (response.data.success) {
                managementConsoleStore.emit('organizationCreatedEvent')
            }
            else {
                console.log(response);
                messageQueue.sendError(response.data.errorMessage, 'Could not Create Organization');
                managementConsoleStore.emit('createOrganizationFailedEvent');
            }
        }).catch ((error) => {
            messageQueue.sendError(error.message, 'Could not Create Organization');
        })
    }

    async beginTrialPeriod() {
        axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+"/Trial/Start", {
            JwtRequestToken : await authenticationStore.getAccessToken(),
            ApplicationName : "CrystalControlWeb"
        }).then((response) => {
            if (response.data.success) {
                // if we created a new organization, automatically attempt to start the trial period
                managementConsoleStore.emit('trialPeriodBegunEvent')
            }
        }).catch ((error) => {
            console.log(error);
            messageQueue.sendError(moduleStore.getString('errorActivatingTrialPeriod'));
        })
    }

    async loadUserData() {
        axios.post(moduleStore.getEnvironmentSetting('managementConsoleApi')+"/UserData", {
            JwtRequestToken : await authenticationStore.getAccessToken()
        }).then((response) => {
            managementConsoleStore.emit('userDataLoadedEvent', response.data)
        }).catch ((error) => {
            console.log(error);
            messageQueue.sendError(moduleStore.getString('errorLoadingUserData'));
        })
    }
}

const managementConsoleStore = new ManagementConsoleStore();
export default managementConsoleStore;