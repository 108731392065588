import EventEmitter from 'events';

class MessageQueue extends EventEmitter {
    /**
     * Display a message on the message store
     * @param text body of message
     * @param displayTime millis to display for, null for infinite. defaults to 5000
     * @param manner positive, negative or empty (for neutral)
     * @param header header of message
     */
    sendMessage(text, displayTime = 5000, manner = '', header = '') {
        let publishTime = new Date().getTime();

        this.emit('newMessageEvent', {
            text, displayTime, manner, header, publishTime
        })
    }

    sendSuccess(text, header='') {
        this.sendMessage(text, 5000, 'positive', header);
    }

    sendError(text, header='Error') {
        this.sendMessage(text, 5000, 'negative', header);
    }
}

const messageQueue = new MessageQueue();
export default messageQueue;