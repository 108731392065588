const _celsiusValues = ['C', 'CELSIUS', '°C', 'DEG. C'];
const _fahrenheitValues = ['F', 'FAHRENHEIT', '°F', 'DEG. F'];

export default class TemperatureValue {

    constructor(value, unit='C') {
        if (_celsiusValues.includes(unit))
            this._value = Number(value);
        else
            this._value = this.convertToCelsius(value, unit);
    }

    convertToCelsius(value, unit) {
        if (_fahrenheitValues.includes(unit)) {
            return (value - 32) * (5/9);
        }
    }

    getValue(unit='C') {
        if (_celsiusValues.includes(unit)) {
            return this._value;
        }
        else if (_fahrenheitValues.includes(unit)) {
            return (this._value * (9 / 5)) + 32;
        }
    }

    static isValidUnit(unit) {
        return _celsiusValues.includes(unit) ||
            _fahrenheitValues.includes(unit);
    }
}
