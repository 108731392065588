
export default class Utils {
    static ReloadPage() {
        window.location.reload()
    }

    static GetCreditCardIcon(issuer) {
        switch (issuer) {
            case "visa":
                return 'cc visa';
            case "discover":
                return 'cc discover';
            case "mastercard":
                return 'cc mastercard';
            default:
                return 'credit card';
        }
    }

    static FormatCurrency(amount) {
        return "$" + amount.toFixed(2);
    }

    static AssignValueToNestedProp(obj, prop, value) {
        if (typeof prop === "string")
            prop = prop.split(".");

        if (prop.length > 1) {
            let e = prop.shift();
            this.AssignValueToNestedProp(obj[e] =
                    Object.prototype.toString.call(obj[e]) === "[object Object]"
                        ? obj[e]
                        : {},
                prop,
                value);
        } else
            obj[prop[0]] = value;
    }

    static GetNestedPropValue(obj, prop) {
        prop = prop.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        prop = prop.replace(/^\./, '');           // strip a leading dot
        let a = prop.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in obj) {
                obj = obj[k];
            } else {
                return;
            }
        }
        return obj;
    }
}
