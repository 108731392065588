import React, { Component } from 'react';
import {Button, Modal, Grid, Step} from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";
import BillingPlanSelection from "./BillingPlanSelection";
import SubscriptionTierSelection from "./SubscriptionTierSelection";
import CheckoutSubscription from "./CheckoutSubscription";
import SubscriptionConfirmation from "./SubscriptionConfirmation";
import Utils from "../utils/Utils";
import managementConsoleStore from "../stores/ManagementConsoleStore";

export default class CreateSubscriptionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible : false,
            step : 'tier',
            tier : null,
            plan : null,
        };

        managementConsoleStore.on('subscriptionStartedEvent', () => this.setState({step : 'confirmation'}));
    }

    open = () => this.setState({visible : true, step : 'tier'});
    closeOrBack = () => {
        if (this.state.step === 'tier')
            this.setState({visible : false});
        if (this.state.step === 'plan')
            this.setState({step : 'tier'});
        if (this.state.step === 'checkout')
            this.setState({step : 'plan'});
        if (this.state.step === 'confirmation')
            Utils.ReloadPage();
    };

    onTierSelection = (tier) => this.setState({tier, step : 'plan'});
    onPlanSelection = (plan) => this.setState({plan, step : 'checkout'});

    render() {
        return (
            <Modal onOpen={this.open} open={this.state.visible}
                   trigger={ this.props.trigger ||
                       <Button content={ this.props.hasCreateSubscriptionPermission ? moduleStore.getString('subscribeToUnlockFeatures') : moduleStore.getString('contactAdminToSubscribe')} color='orange'
                               icon='shopping cart' size='small' disabled={!this.props.hasCreateSubscriptionPermission}/>
                   }>
                <Modal.Header>{moduleStore.getString('createNewSubscription')}</Modal.Header>
                <Modal.Content>

                    { this.state.step === 'tier' ?
                        <SubscriptionTierSelection onTierSelection={this.onTierSelection}/> : null }

                    { this.state.step === 'plan' ?
                        <BillingPlanSelection onPlanSelection={this.onPlanSelection} tier={this.state.tier}/> : null }

                    { this.state.step === 'checkout' ?
                        <CheckoutSubscription tier={this.state.tier} plan={this.state.plan} autoAssign={this.props.autoAssign}/> : null }

                    { this.state.step === 'confirmation' ?
                        <SubscriptionConfirmation /> : null }

                </Modal.Content>
                <Modal.Actions>
                    <Grid verticalAlign='middle'>
                        <Grid.Column textAlign='left' width={12}>
                            <Step.Group size='mini'>

                                <Step active={this.state.step === 'tier'} completed={this.state.step !== 'tier'}
                                      icon='bars' title={moduleStore.getString('accessLevel')} description={moduleStore.getString('standardOrAdvanced')} />

                                <Step active={this.state.step === 'plan'} completed={this.state.step === 'checkout' || this.state.step === 'confirmation'}
                                      icon='calendar' title={moduleStore.getString('billingPlan')} description={moduleStore.getString('billingFrequency')} />

                                <Step active={this.state.step === 'checkout'} completed={this.state.step === 'confirmation'}
                                      icon='credit card' title={moduleStore.getString('checkout')} description={moduleStore.getString('beginSubscription')} />

                            </Step.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button onClick={this.closeOrBack} color={this.state.step === 'confirmation' ? 'green' : undefined}
                                    content={this.state.step === 'tier' ? moduleStore.getString('cancel')
                                            :this.state.step === 'confirmation' ? moduleStore.getString('done')
                                            :moduleStore.getString('back')}/>
                        </Grid.Column>
                    </Grid>

                </Modal.Actions>
            </Modal>
        )
    }
}
