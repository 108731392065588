export default class SimpleVersion {
    constructor(version, minor=null, patch=null, build=null) {
        if (minor !== null && patch !== null) {
            this.major = Number(version);
            this.minor = Number(minor);
            this.patch = Number(patch);
            this.build = Number(build);
        }
        else {
            let parsed = version.split('.');
            if (parsed.length === 2 && parsed[0].startsWith('R16')) {
                this.major = Number(parsed[0].substring(5));
                this.minor = Number(parsed[1]);
            }
            if (parsed.length === 3 || parsed.length === 4) {
                this.major = Number(parsed[0]);
                this.minor = Number(parsed[1]);
                this.patch = Number(parsed[2]);
            }
            if (parsed.length === 4) {
                this.build = Number(parsed[3]);
            }
        }
    }

    equals(other) {
        if (! other ) return false;
        return this.major === other.major &&
               this.minor === other.minor &&
               this.patch === other.patch &&
               (!this.build || !other.build || this.build === other.build);
    }

    isNewerThan(other) {
        if (! other ) return false;

        if (this.major !== other.major)
            return other.major < this.major;
        if (this.minor !== other.minor)
            return other.minor < this.minor;
        if (this.patch !== other.patch)
            return other.patch < this.patch;
        if (!!this.build && !!other.build && this.build !== other.build)
            return other.build < this.build;

        return false;
    }

    toString() {
        return !this.build
            ? `${this.major}.${this.minor}.${this.patch}`
            : `${this.major}.${this.minor}.${this.patch}.${this.build}`;
    }
}
