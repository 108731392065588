import * as React from 'react';
import {JS} from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {Checkbox, Input, Button, Modal, Message, Grid, Form} from 'semantic-ui-react'
import '@ametektci/ametek.semanticuitheme/dist/semantic.min.css';
import AuthHeader from "./authHeader";
import {AuthPiece} from 'aws-amplify-react';
import {FormField, InputLabel} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";
import textResources from "./language/TextResources";
import CustomStyle from "./customStyle";

export default class AmetekSignIn extends AuthPiece {
    constructor(props) {
        super(props);

        this.checkContact = this.checkContact.bind(this);
        this._validAuthStates = ['signIn'];
        this.state = {
            persist: false,
            errorMessage : null,
        };
    }

    checkContact(user) {
        if (!Auth || typeof Auth.verifiedContact !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        Auth.verifiedContact(user)
            .then(data => {
                if (!JS.isEmpty(data.verified)) {
                    this.changeState('signedIn', user);
                } else {
                    user = Object.assign(user, data);
                    this.changeState('verifyContact', user);
                }
            });
    }

    toggleRememberMe () {
        this.setState(prevState => ({persist: !prevState.persist}));
    };

    async signIn(event) {
        // avoid submitting the form
        if (event) {
            event.preventDefault();
        }

        let username = this.inputs.email.toLowerCase();
        let password = this.inputs.password;

        if (!username || !password) {
            this.setState({errorMessage : textResources.Strings.emailAndPasswordCannotBeEmpty});
            return;
        }

        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        this.setState({loading: true});
        try {
            let user = await Auth.signIn(username, password);
            this.checkContact(user);

            // if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            //     logger.debug('confirm user with ' + user.challengeName);
            //     this.changeState('confirmSignIn', user);
            // } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //     logger.debug('require new password', user.challengeParam);
            //     this.changeState('requireNewPassword', user);
            // } else if (user.challengeName === 'MFA_SETUP') {
            //     logger.debug('TOTP setup', user.challengeParam);
            //     this.changeState('TOTPSetup', user);
            // } else {
            //     this.checkContact(user);
            // }
        } catch (error) {
            this.setState({errorMessage : error.message})
        } finally {
            this.setState({loading: false})
        }
    }

    handleSignUpClick() {
        if (this.props.redirectSignUp) {
            window.location = this.props.redirectSignUp + "?signUp";
        }
        else {
            this.changeState('signUp')
        }
    };

    showComponent(theme) {
        let inviteCodeSpecified = false;

        let url = new URL(window.location.href);
        if (url.searchParams.has('account') && url.searchParams.has('joinOrganizationCode') ) {
            this.inputs.email =  url.searchParams.get('account');
            inviteCodeSpecified = true;
        }
        return (
            <Modal open={true} size='small'>
                <Modal.Content>
                    <AuthHeader title={textResources.Strings.accountLogin}/>

                    { inviteCodeSpecified ? <Message content={textResources.Strings.signInOrCreate}
                                                     positive /> :
                                            null
                    }

                    <Form onSubmit={this.signIn.bind(this)} error={!!this.state.errorMessage}>
                        <FormField theme={theme} style={CustomStyle.FormField}>
                            <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.email}</InputLabel>
                            <Input fluid size='large'
                                   placeholder={textResources.Strings.email}
                                   theme={theme}
                                   key="email"
                                   type="email"
                                   name="email"
                                   disabled={inviteCodeSpecified}
                                   value={inviteCodeSpecified ? this.inputs.email : undefined}
                                   onChange={this.handleInputChange}
                            />
                        </FormField>
                        <FormField theme={theme} style={CustomStyle.FormField}>
                            <InputLabel theme={theme} style={CustomStyle.InputLabel}>{textResources.Strings.password}</InputLabel>
                            <Input fluid size='large'
                                   placeholder={textResources.Strings.password}
                                   key="password"
                                   type="password"
                                   name="password"
                                   onChange={this.handleInputChange}
                            />
                        </FormField>

                        <Message content={this.state.errorMessage} error/>

                        <FormField theme={theme} style={CustomStyle.FormField}>
                            <Checkbox label={textResources.Strings.rememberMe}
                                      key='persist'
                                      name='persist'
                                      type='checkbox'
                                      onChange={this.toggleRememberMe.bind(this)}/>
                        </FormField>

                        <Grid>
                            <Grid.Column width={5}>
                                <Button basic
                                        type='button'
                                        size='small'
                                        disabled={this.state.loading}
                                        content={textResources.Strings.forgotPassword}
                                        onClick={() => this.changeState('forgotPassword')}
                                />
                            </Grid.Column>
                            <Grid.Column width={11} textAlign='right'>
                                <Button
                                    type='button'
                                    size='large'
                                    disabled={this.state.loading}
                                    content={textResources.Strings.signUp}
                                    onClick={this.handleSignUpClick.bind(this)}
                                />

                                <Button
                                    color='red'
                                    size='large'
                                    type='submit'
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                    content={textResources.Strings.signIn}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}