import PressureValue from '../utils/PressureValue';
import TemperatureValue from '../utils/TemperatureValue';

function GetMinAvgMax(readings) {
    let min = Number.MAX_VALUE, max = Number.MIN_VALUE, sum = 0;

    for (let r of readings) {
        min = (r < min) ? r : min;
        max = (r > max) ? r : max;
        sum += r;
    }

    return [min, sum / readings.length, max];
}

function GetPointForTimestamp(timestamp, valuesAtTimestamp) {
    // add the x value (the timestamp)
    let convertedPoint = [new Date(timestamp)]

    // check all the readings
    for(let sensorReadings of valuesAtTimestamp) {
        // if there is only one reading for this timestamp, add the single number
        if (sensorReadings.length === 1)
            convertedPoint.push([sensorReadings[0], sensorReadings[0], sensorReadings[0]])
        // if there are multiple readings, get min avg and max (dygraphs format for error bars)
        else
            convertedPoint.push(GetMinAvgMax(sensorReadings))
    }

    return convertedPoint;
}

export default class DataConversions {
    static ConvertUnitData(log, csvData, pressureUnit, temperatureUnit) {
        let dataPoints = csvData.trim().split('\r');

        // ignore file header for the data
        // line 1 : model number
        dataPoints.shift();
        // line 2 : part number
        dataPoints.shift();
        // line 3 : serial number
        dataPoints.shift();
        // line 4 : run tag
        dataPoints.shift();
        // line 5 : start time
        dataPoints.shift();
        // line 6 : end time
        dataPoints.shift();
        // line 7 : units
        dataPoints.shift();
        // line 8 : resolution
        dataPoints.shift();
        // line 9 : data header
        dataPoints.shift();

        let numberOfSensors = dataPoints[0].split(',').length - 1;

        let valueTypes = [];
        for (let sensor = 1; sensor <= numberOfSensors; sensor++) {
            let unit = log['sensor' + sensor + "Unit"];

            if (PressureValue.isValidUnit(unit)) {
                log['sensor' + sensor + "Unit"] = pressureUnit;
                valueTypes.push(0);
            }
            else if (TemperatureValue.isValidUnit(unit)) {
                log['sensor' + sensor + "Unit"] = temperatureUnit;
                valueTypes.push(1);
            }
            else {
                console.log("Couldn't determine unit", unit);
                valueTypes.push(-1)
            }
        }

        let convertedData = [];

        // keep track of all the readings for each time stamp, and only add this value when
        // we move to the next timestamp - this will handle readings with the same timestamp and show error
        // bars for that
        let currentTimeStamp = null;
        let valuesAtTimestamp = [];

        for(let point of dataPoints) {
            // split the csv row into readings
            let values = point.split(',');

            // if we've moved to a different time stamp
            if (values[0] !== currentTimeStamp)
            {
                // if there are readings at this timestamp
                if (0 < valuesAtTimestamp.length)
                    convertedData.push(GetPointForTimestamp(currentTimeStamp, valuesAtTimestamp))

                //reset the timestamp readings
                currentTimeStamp = values[0];
                valuesAtTimestamp = [];
                for (let sensor =1; sensor <= numberOfSensors; sensor += 1)
                    valuesAtTimestamp.push([])

            }
            // convert each sensor, skipping the 0 position, since that is a time
            for (let sensor = 1; sensor <= numberOfSensors; sensor += 1) {
                // get the value for this sensor
                let value = values[sensor];

                // convert the value
                if (valueTypes[sensor-1] === 0) { // pressure
                    value = new PressureValue(value).getValue(pressureUnit)
                }
                else if (valueTypes[sensor-1] === 1) { // temperature
                    value = new TemperatureValue(value).getValue(temperatureUnit)
                }
                else {
                    value = Number(value) // default to just a number
                }
                // save this sensor reading to the readings for this timestamp
                valuesAtTimestamp[sensor-1].push(value)
            }
        }

        // when we've hit the end, add the last timestamp of data to the dataset
        if (0 < valuesAtTimestamp.length)
            convertedData.push(GetPointForTimestamp(currentTimeStamp, valuesAtTimestamp))

        return convertedData;
    }
}