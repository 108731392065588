import React, { Component } from 'react';
import {Header, Message} from 'semantic-ui-react';
import moduleStore from "../settings/ModuleStore";

export default class SubscriptionConfirmation extends Component {
    render() {
        return (
            <div>
                <Header dividing content={moduleStore.getString('confirmation')} />

                <Message positive icon='check' header={moduleStore.getString('subscriptionActive')}
                         content={moduleStore.getString('subscriptionActiveDescription')} />

                <Message size='small' icon='mail' header={moduleStore.getString('emailReceiptSent')}
                         content={moduleStore.getString('emailReceiptDescription')} />

            </div>
        )
    }
}
