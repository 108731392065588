import React, { Component } from 'react';
import {Dropdown, Flag} from 'semantic-ui-react';
import textResources from "./TextResources";

const LanguageOptions = [
    {flag : 'us', key : 'english', value : 'english', text : "English"},
    {flag : 'fr', key : 'french', value : 'french', text : "Français"},
    {flag : 'de', key : 'german', value : 'german', text : "Deutsche"},
    {flag : 'es', key : 'spanish', value : 'spanish', text : "Español"},
    {flag : 'cn', key : 'chinese', value : 'chinese', text : "中文"}
];

export default class LanguageSelection extends Component {
    constructor(props) {
        super(props);

        let currentLanguage = textResources.getCurrentLanguage();
        let currentFlag = LanguageOptions.find(lang => lang.value === currentLanguage).flag;

        this.state = { currentLanguage, currentFlag };
    }

    handleChange(e, {value}) {
        // do nothing if we didn't select a new option
        if (value === this.state.currentLanguage) return;

        textResources.setCurrentLanguage(value);
    };

    render() {
        return (
            <Dropdown item trigger={<Flag name={this.state.currentFlag} />} onChange={this.handleChange.bind(this)} options={LanguageOptions} direction='left' />
        )
    }
}
