import React, { Component } from 'react';
import {Transition, Message, Portal, Feed } from 'semantic-ui-react';
import messageQueue from "./MessageQueue";

export default class MessageFeed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages : []
        };

        messageQueue.on('newMessageEvent', this.addMessage);
    }

    addMessage = (newMessage) => {
        this.setState(prevState => ({
            messages : [...prevState.messages, newMessage]
        }));

        // if display time is specified
        if (newMessage.displayTime) {
            setTimeout(() => {
                this.clearMessage(newMessage.publishTime);
            }, newMessage.displayTime);
        }
    }

    clearMessage = (publishTime) => this.setState({messages: this.state.messages.filter((message) => message.publishTime !== publishTime)})

    render() {
        return (
            <Portal open={true}>
                <Transition.Group as={Feed} duration={800} animation='fly left' style={{
                    right: '5%', position: 'fixed', bottom: '5%', zIndex: 10000,
                }}>
                    {
                        this.state.messages.map(message => (
                            <Feed.Event key={message.publishTime}>
                                    <Message compact size='large'
                                             positive={message.manner === 'positive'} negative={message.manner === 'negative'}>
                                        <Message.Header>{message.header}</Message.Header>
                                        {message.text}
                                    </Message>
                            </Feed.Event>
                        ))
                    }
                </Transition.Group>
            </Portal>
        )
    }
}
