const conversions = {
    'psi' : '1',
    'psi g' : '1',
    'psi a' : '1',
    'inh2o' : '27.6805992126465',
    'mmh2o' : '703.086975097656',
    'inhg' : '2.03602004051208',
    'mmhg' : '51.714900970459',
    'kpa' : '6.89480018615723',
    'mpa' : '0.00689479988068342',
    'kgcmsq' : '0.0703070014715195',
    'bar' : '0.0689480006694794',
    'mbar' : '68.948',
    'cmh2o' : '70.3087005615234',
    'fth2o' : '2.30680513381958',
    'inh2o_68f' : '27.6806'
};

export default class PressureValue {
    constructor(value, unit='psi') {
        if (unit === 'psi')
            this._value = Number(value);
        else
            this._value = value / this.getMultiplier(unit);
    }

    getMultiplier(unit) {
        let conversion = conversions[unit.toLowerCase()];

        if (!conversion)
            throw new Error("Unknown unit " + unit);

        return Number.parseFloat(conversion);
    }

    getValue(unit='psi', decimalPlaces) {
        return this._value * this.getMultiplier(unit);
    }

    static isValidUnit(unit) {
        return !! unit &&
               !! conversions[unit.toLowerCase()];
    }
}