import * as React from 'react';
import '@ametektci/ametek.semanticuitheme/dist/semantic.min.css';
import {AuthPiece} from 'aws-amplify-react';

export default class AmetekSplashTemplate extends AuthPiece {
    constructor(props) {
        super(props);

        this._validAuthStates = ['splash'];
    }

    showSignIn = () => this.changeState('signIn');
    showSignUp = () => this.changeState('signUp');

    showComponent(theme) {
        return (
            <div>
                Splash auth component template is not being properly inherited. Ensure it implements the showComponent method
            </div>
        );
    }
}